import ResetPasswordMain from "components/ForgotPassword/ResetPasswordMain";
import React from "react";

const ResetPasswordPage = () => {
  return (
    <>
      <ResetPasswordMain />
    </>
  );
};

export default ResetPasswordPage;
