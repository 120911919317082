/* eslint-disable max-len */
/* eslint-disable newline-per-chained-call */
import React, { useEffect } from "react";

import { Dropdown, Flex, Layout, Menu, MenuProps, Space, theme, Tooltip } from "antd";
import { DownOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";

import { getCreditInfo } from "store/slices/auth.slice";

const { Header, Content } = Layout;

const MainLayout: React.FC = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.userDetails);
  const availableCredit = useAppSelector((state) => state.auth.availableCredit);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {
    token: { borderRadiusLG, colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    dispatch(getCreditInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items: MenuProps["items"] = [
    // {
    //   key: "1",
    //   label: (
    //     <a  onClick={()=>{
    //       navigate("/profile");
    //     }}>
    //       Profile
    //     </a>
    //   ),
    // },
    {
      key: "2",
      label: (
        <a
          onClick={() => {
            navigate("/logout");
          }}
        >
          Logout
        </a>
      ),
      // icon: <SmileOutlined />,
      // disabled: true,
    },
  ];

  const menuItems: MenuProps["items"] = [
    {
      key: "/",
      label: "PAN List",
      onClick: () => navigate("/"),
    },
    {
      key: "/crime-verification",
      label: "Crime Verification",
      onClick: () => navigate("/crime-verification"),
    },
    {
      key: "/add-identity-verification",
      label: "Identity Verification",
      onClick: () => navigate("/add-identity-verification"),
    },
    {
      key: "/add-business-verification",
      label: "Business Verification",
      onClick: () => navigate("/add-business-verification"),
    },
    {
      key: "/teams",
      label: "Teams",
      onClick: () => navigate("/teams"),
    },
    {
      key: "/order-list",
      label: "Recharge",
      onClick: () => navigate("/order-list"),
    }
  ];

  const filteredMenuItems = menuItems.filter((item) => {
    if (!item) return false; 
    if ((item.key === "/teams" || item.key === "/order-list") && user?.role.toLowerCase() !== "admin") {
      return false;
    }
    return true;
  });

  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 999,
          width: "100%",
          padding: "0 20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Flex gap={10} style={{ flexGrow: 1, width: "100%" }}>
          <Link to="/" style={{ display: "flex" }}>
            <img src="Logo-white.svg" alt="" style={{ maxWidth: "7rem", height: "auto", margin: "auto 0" }} />
          </Link>
          <Flex justify="space-between" align="center" style={{ flexGrow: 1, width: "100%" }}>
            <Menu
              className="header-menu"
              theme="dark"
              mode="horizontal"
              selectedKeys={[location.pathname]}
              items={filteredMenuItems}
            />
            <Flex gap={8} align="center">
              <div>
                <Tooltip title="Credit Balance">
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid white",
                      padding: "4px 10px",
                      color: "white",
                      height: "30px",
                      lineHeight: "unset",
                      alignItems: "center",
                      margin: "auto 0",
                      gap: "6px",
                      fontSize: "16px",
                      borderRadius: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/recharge")}
                  >
                    <PlusCircleOutlined />
                    <span
                    >
                      {availableCredit}
                    </span>
                  </div>
                </Tooltip>
              </div>
              <Dropdown menu={{ items }}>
                <a onClick={(e) => e.preventDefault()} style={{ color: "#fff" }}>
                  <Space style={{ flexWrap: "nowrap" }}>
                    {/* Welcome  */}
                    {user?.firstName + " " + user?.lastName}
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Flex>
          </Flex>
        </Flex>
        {/* <div className="demo-logo">
          <Link to="/" style={{ display: "flex" }}>
            <img src="Logo-white.svg" alt="" style={{ maxWidth: "7rem", height: "auto", margin: "auto 0" }} />
          </Link>
        </div> */}
        {/* <div style={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
          {" "}
          <Menu
            style={{ alignItems: "center", height: "100%" }}
            theme="dark"
            mode="horizontal"
            selectedKeys={[location.pathname]}
            items={menuItems}
          />
          <div style={{ display: "flex" }}>
            <div>
              <span
                style={{
                  borderRadius: "5px",
                  padding: "4px 8px",
                  marginRight: "5px",
                  fontSize: "16px",
                  display: "inline",
                  background: "#fff",
                }}
              >
                Balance Credit : {availableCredit}
              </span>
            </div>
            <Dropdown menu={{ items }}>
              <a onClick={(e) => e.preventDefault()} style={{ color: "#fff" }}>
                <Space>
                  {user?.firstName + " " + user?.lastName}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
        </div> */}
      </Header>
      <Content style={{ padding: "0 0" }}>
        <div
          style={{
            padding: "0px 0px",
            minHeight: 380,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
        </div>
      </Content>
      {/* <Footer style={{ textAlign: "center" }}>Verifyme.ai {new Date().getFullYear()}</Footer> */}
    </Layout>
  );
};

export default MainLayout;
