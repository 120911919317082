/* eslint-disable @typescript-eslint/no-explicit-any */
import { httpApi } from "api/http.api";
import { IUser } from "interfaces/IUser";


export interface AuthData {
  email: string;
  password: string;
}

export interface VerifyEmailRequest {
  token: string;
}

export interface SignUpRequest {
  title: string; // Assuming title will always be a string like "Mr"
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  mobileNumber: string;
  acceptTerms: boolean;
}
export interface SignUpResponse {
  message: string;
}


export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface RefreshClient {
  client_id: string;
}

export interface RefreshTokenRequest {
  refreshToken: string;
}


export interface LoginResponse {
  token: string;
  user: IUser;
  refresh_token: string;
}

interface PasswordResetRequestPayload {
  token: string // token can be null if not found in the URL
  password: string;
  confirmPassword: string;
}

export const login = (loginPayload: LoginRequest): Promise<IUser> =>
  httpApi
    .post<IUser>("accounts/authenticate", { ...loginPayload })
    .then(({ data }) => data);

export const forgotPassword = (email: string): Promise<any> =>
  httpApi
    .post<any>("accounts/forgot-password", { email })
    .then(({ data }) => data);

export const passwordReset = (resetData: PasswordResetRequestPayload): Promise<any> =>
  httpApi.post<any>("accounts/reset-password", { ...resetData }).then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<SignUpResponse> =>
  httpApi.post<SignUpResponse>("accounts/register", { ...signUpData }).then(({ data }) => data);

export const verifyEmail = (token: string): Promise<any> =>
  httpApi.post<any>("accounts/verify-email", { token: token }).then(({ data }) => data);


export const creditInfo = (): Promise<any> =>
  httpApi.get<any>("accounts/credit-info").then(({ data }) => data);


export const refreshToken = (refreshToken: RefreshTokenRequest): Promise<LoginResponse> =>
  httpApi
    .post<LoginResponse>("CmsAuth?callType=persistent", { ...refreshToken })
    .then(({ data }) => data);

export const setClientToken = (request: { client_id: string }): Promise<LoginResponse> =>
  httpApi
    .post<LoginResponse>("CmsAuth?callType=set_client_of_account", { ...request })
    .then(({ data }) => data);



export const resetPassword = (
  resetPasswordPayload: ResetPasswordRequest
): Promise<undefined> =>
  httpApi
    .post<undefined>("forgotPassword", { ...resetPasswordPayload })
    .then(({ data }) => data);

export const verifySecurityCode = (
  securityCodePayload: SecurityCodePayload
): Promise<undefined> =>
  httpApi
    .post<undefined>("verifySecurityCode", { ...securityCodePayload })
    .then(({ data }) => data);

export const setNewPassword = (
  newPasswordData: NewPasswordData
): Promise<undefined> =>
  httpApi
    .post<undefined>("setNewPassword", { ...newPasswordData })
    .then(({ data }) => data);


