export const camelize = (string: string): string => {
  return string
    .split(" ")
    .map((word, index) =>
      index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)
    )
    .join("");
};

export const getDate = (date: string): string => {
  if (!date) return "";
  return date.split("T")[0];
};

export const getCurrencyPrice = (price: number, currency: string): string => {
  switch (currency) {
    case "USD": {
      return `$${price}`;
    }

    default: {
      return `$${price}`;
    }
  }
};

export const parseJwt = (token: string) => {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export const trimStr = (str: string, charCount = 15) => {
  if (!str) return "";
  const res = str.substring(0, charCount);
  return res + "...";
};

export const isMorePages = (dataLength: number, perPageRecord = 10) => {
  dataLength = dataLength ? dataLength : 0;
  return dataLength > perPageRecord ? true : false;
};

export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;



export const validateObj: any = {
  aadhaar: {
    regEx: /^\d{12}$/,
    maxlength: 10,
  },
  pan: {
    regEx: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  },
  pan_allin_one: {
    regEx: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  },
  credit_score: {
    regEx: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  },
  voter: {
    regEx: /^[A-Z]{3}[0-9]{7}$/,
  },
  gst: {
    regEx: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  },
  tan: {
    regEx: /[A-Z]{4}[0-9]{5}[A-Z]{1}/,
  },
  udyam_registration_verification: {
    regEx: /^UDYAM-[A-Z]{2}-\d{2}-\d{7}$/,
  },
  din: {
    regEx: /^\d{8}$/,
  },
  dl: {
    regEx: /^(([A-Z]{2}[0-9]{2})|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
  },
  passport: {
    regEx: /^[A-Z0-9]+$/,
  },
  company_name: {
    regEx: /^[LU][0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/,
  },
  rc: {
    regEx: /^[A-Z|a-z]{2}\s?[0-9]{1,2}\s?[A-Z|a-z]{0,3}\s?[0-9]{4}$/,
  },
  uan: {
    regEx: /^[0-9]{10}$/,
  },
  pinCode: {
    regEx: /^[1-9][0-9]{5}$/
  }
};