import ForgotPasswordMain from "components/ForgotPassword/ForgotPasswordMain";
import React from "react";

const ForgotPasswordPage = () => {
  return (
    <>
      <ForgotPasswordMain />
    </>
  );
};

export default ForgotPasswordPage;
